/*@tailwind base;
@tailwind components;
@tailwind utilities;*/
.mainContainer{
	display: flex;
	height: 100vh;
}
.disabledUser {
  pointer-events: none;
  opacity: 0.5;
}
.KbmChatHomeSec{
	width: 70%;
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e6ed;
}
.KbmChatHomeSec h2,.KbmChatHomeSec p{
	color: #00473f;
}
.authForm {
	height: 100vh;
}
.authForm img{
	width: 240px;
}
.authForm h3{
	font-size: 28px;
	color: #00473f;
	padding-bottom: 10px;
}
.form-control:focus{
	border-color: #00473f;
	box-shadow: none;
}
.passwordField{
	position: relative;
}
.passwordField button{
	position: absolute;
	margin-top: 0 !important;
  top: 4px;
  right: 4px;
  background: transparent;
  border: 0;
}
.profile_image_upload img{
	width: 120px;
	height: 120px;
	border-radius: 100%;
	margin-bottom: 20px;
}
.profile_image_upload span{
	border: 1px solid #00473f;
  padding: 4px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #00473f;
  cursor: pointer;
}
.authForm button{
	margin-top: 20px;
}
.sideBarMain{
	width: 30%;
	position: relative;
}
.sidebarHeader{
	display: flex;
	background-color: #00473f;
	align-items: center;
  justify-content: space-between;
}
.userInfoChat img{
	width: 32px;
  height: 32px;
  border-radius: 100%;
}
.sidebarHeader ul{
	padding: 0;
	margin: 0;
	display: flex;
	list-style-type: none;
}
.sidebarHeader li svg{
	fill: #e0e6ed;
}
.notificationSec{
	background-color: #e0e6ed;
  padding: 4px 10px;
}
.notificationSec .bellIcon svg{
	width: 38px;
	height: 38px;
	fill: darkcyan;
}
.notificationSec .notificationContent span{
	font-size: 12px;
	color: #000;
}
.notificationSec .notificationContent span svg{
	margin-left: 5px;
	height: 10px;
}
.notificationCLose svg{
	height: 20px;
	width: 20px;
}
.rotateIcon svg{
	animation: rotate 0.3s forwards;
}
@keyframes rotate{
	0%{
		transform: rotate(-90deg);
	}
}
.searchSidebar{
	background: #00473f;
  padding: 4px 10px;
  display: flex;
  align-items: center;
}
.searchSidebar input::placeholder{
	font-size: 14px;
	color: #e0e6ed;
}
.searchSidebar input:focus{
	outline: 0;
}
.searchSidebar input{
	background: transparent;
  border: 0;
  color: #e0e6ed;
  font-size: 14px;
  width: 100%;
}
.searchSidebar svg{
	fill: #e0e6ed;
}
.sideBarMain .chat.scrollBar ul{
	padding: 0;
	margin: 0;
	list-style-type: none;
}
.sideBarMain .chat.scrollBar li img{
	width: 36px;
	height: 36px;
	border-radius: 100%;
}
.sideBarMain .chat.scrollBar li{
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 10px;
  border-bottom: 1px solid lightgrey;
  position: relative;
}
.sideBarMain .chat.scrollBar li:last-child{
	border-bottom: 0;
}
.sideBarMain .chat.scrollBar li:hover,.activeChat{
	background: #e0e6ed;
}
.online{
	border: 2px solid green;
}
.chatDetail{
	margin-right: auto;
	padding-left: 10px;
}
.chatDetail h6{
	color: #00473f;
	margin: 4px 0 0;
    font-size: 14px;
}
.chatDetail p{
	font-size: 12px;
	color: #00473f;
  margin: 0;
  font-weight: 500;
}
.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
}
.boldMessage{
	color: red;
}
.chatDate small{
	color: #00473f;
	font-size: 11px;
	margin-top: 20px;
    display: block;
    white-space: nowrap;
}
.sidebarHeaderMenu{
	position: absolute;
	right: 0;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 99;
}
.sidebarHeaderMenu ul{
	display: flex;
	flex-direction: column;
	padding: 10px 0;
}
.sidebarHeaderMenu li{
	padding: 2px 20px;
	cursor: pointer;
}
.sidebarHeaderMenu li:hover{
	background-color: #00473f;
	color: #fff;
}
.sidebarHeaderMenu li span{
	font-size: 13px;
	white-space: nowrap;
}
.btn:focus{
	box-shadow: none;
}
.sidebarHeaderMenuBg{
	background-color: #555;
}
.searchResultSec,.chat.scrollBar{
	overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00473f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chatHeader img{
	width: 36px;
	height: 36px;
	border-radius: 100%;
}
.chatHeader{
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #e0e6ed;
  padding: 3px 6px;
}
.grpUsersName{
	padding-right: 4px;
}
.chatHeaderInfo{
	line-height: 1;
	padding-left: 10px;
}
.chatHeaderInfo h5{
	font-size: 16px;
	text-transform: capitalize;
	margin: 0;
}
.chatHeaderInfo small{
	font-size: 12px;
}
.messagePanel{
	width: 70%;
	position: relative;
	border-left: 1px solid lightgrey;
}
.chatHeader ul{
	padding: 0;
	margin: 0;
	display: flex;
	list-style-type: none;
}
.chatHeader li svg{
	width: 20px;
	height: 20px;
}
.chatHeader li .videoDialBtn svg{
	width: 30px;
	height: 30px;
}
.audioDialBtn svg{
	transform: rotate(130deg);
}
.messageBg1{
	background-color: #00473f;
	max-width: 50%;
}
.messageBg2{
	max-width: 50%;
	background-color: dimgray;
}
.messageScroll{
	overflow-y: auto;
	padding: 20px;
}
.textBox{
	padding: 4px 10px;
    border-radius: 6px;
    margin-bottom: 4px;
}
.textBox img{
	width: 200px;
	height: 200px;
	object-fit: contain;
}
.textBox p{
	font-size: 15px;
}
.textBox p a{
	color: #fff;
	word-wrap: break-word;
}
.textBox span{
	font-size: 10px !important;
    text-align: right;
    display: block;
}
.message-status{
	color: #fff;
}
.inputSec{
	background: #00473f;
	padding: 4px;
	position: absolute;
    bottom: 0px;
    width: 100%;
}
.inputSec ul{
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
}
.inputBtn button i{
	color: #fff;
	font-size: 20px;
}
.inputBtn{
	display: flex;
	align-items: center;
}
.inputMessage{
	width: 100%;
}
.inputBtn svg{
	fill: #fff;
}
.EmojiPickerReact ul.epr-emoji-list{
	display: block;
}
.emojiSec{
	position: absolute;
    bottom: 50px;
}
.openEmojiAnimation{
	position: absolute;
	bottom: 50px;
	overflow: hidden;
}
.openEmojiAnimation ul{
	flex-direction: column;
	/*transform: translate(0px, -100%);*/
	transition: .3s;
}
.openEmojiAnimation li button{
	border-radius: 100%;
    padding: 0;
    margin: 4px 0;
}
.typingLoader{
	position: absolute;
	bottom: 50px;
}
.filePreviewHeader{
	display: flex;
	justify-content: space-between;
	width: 100%;
    padding: 10px;
}
.filePreviewHeader p{
	margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
}
.filePreviewPanel img{
	width: 250px;
	height: 250px;
    object-fit: contain;
}
.filePreviewPanel{
	display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
}
.closeFile{
	cursor: pointer;
}
.filePreviewBottom{
	width: 100%;
	padding: 10px;
	border-top: 1px solid lightgrey;
}
.filePreviewBottom img{
	width: 70px;
    height: 70px;
    object-fit: contain;
    border: 1px solid lightgrey;
    margin-right: 6px;
    border-radius: 6px;
}
.filePreviewBottom img.online{
	border: 1px solid green;	
}
.fileHandleAndSend{
	display: flex;
	padding-top: 10px;
	align-items: center;
}
.fileIcon{
	text-align: center;
}
.fileIcon img{
	width: auto;
    height: auto;
}
.fileIcon p{
	margin: 0;
	font-size: 14px;
}
.fileIcon span{
	font-size: 12px;
}
.filePreviewBottom .fileSmallIcon img{
	padding: 6px;
}
.sendFileIcon{
	margin-left: auto;
	display: flex;
    align-items: center;
    background: #00473f;
    width: 50px;
    height: 50px;
    text-align: center;
    justify-content: center;
    border-radius: 100%;
}
.sendFileIcon{
	background: #00473f;
}
.sendFileIcon svg{
	fill: #fff;
}
.addFileSec{
	width: 70px;
    height: 70px;
    object-fit: contain;
    border: 1px solid lightgrey;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
}
.addFileBtn{
	transform: rotate(45deg);
}
.filePreviewBottomFile{
	position: relative;
}
.removeFileIcon{
	position: absolute;
    top: 0;
    right: 7px;
    cursor: pointer;
}
.fileMessageBox{
	display: flex;
	align-items: center;
}
.fileMessageBox img{
	width: 50px;
    height: 50px;
    object-fit: contain;
}
.fileMessageBox p{
	margin: 0;
    color: #fff;
    font-size: 14px;
}
.fileMessageBox span{
	color: #fff;
}
.fileMessageDetail {
	padding: 0 10px;
}
.callPanel,.gettingCall{
	position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(0,0,0,.5);
    transition: .3s;
}
.ringingContent img{
	width: 100px;
    border-radius: 100%;
    height: 100px;
    margin-right: 10px;
}
.ringingSec{
	background-color: #00473f;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .3s;
}
.ringingContent{
	display: flex;
	align-items: center;
}
.ringingContent ul{
	margin: 0;
    list-style-type: none;
    display: flex;
}
.ringingContent li svg{
	width: 20px;
	height: 20px;
	fill: #fff;
}
.ringingContent h5{
	font-size: 16px;
	color: #fff;
	margin: 0;
}
.ringingContent span{
	font-size: 14px;
	color: #fff;
}
.ringingContent li.closeCall{
	background: crimson;
}
.ringingContent li.acceptCall{
	background: cornflowerblue;
}
.ringingContent li{
	border-radius: 100%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    cursor: pointer;
}
.callInfo{
	background-color: #00473f;
	padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .3s;
    width: 340px;
    height: 500px;
    position: relative;
    z-index: 99;
}
.callHeaderSec svg,.callHeaderSec i,.callHeaderSec span{
	color: #fff;
	fill: #fff;
}
.callHeaderSec p{
	margin: 0;
}
.callHeaderSec span{
	font-size: 12px;
	padding-left: 2px;
}
.callHeaderSec svg{
	height: 20px;
	width: 20px;
	cursor: pointer;
}
.callHeaderSec i{
	cursor: pointer;
	font-size: 18px;
}
.callHeaderSec .rotateIcon{
	transform: rotate(-180deg);
}
.callAreaPanel{
	display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}
.callAreaPanel h5{
	margin: 0;
	color: #fff;
}
.callAreaPanel span{
	color: #fff;
	font-size: 14px;
}
.callActionPanel svg, .callActionPanel i{
	width: 20px;
	color: #fff;
	fill: #fff;
	height: 20px;
	font-size: 18px;
}
.callActionPanel ul{
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
}
.callActionPanel li button{
	border: 0;
    background: #555;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
.callActionSec{
	position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #333;
    padding: 10px 20px 20px;
    border-radius: 10px;
    z-index: 9;
}
.callActionPanel li:nth-child(2) svg{
	margin-top: 6px;
}
.callActionPanel li:nth-child(2) svg.videoDialIcon{
	height: 30px;
	width: 30px;
}
.callActionPanel li:last-child button{
	background: crimson;
}
.callActionPanel .arrowButton{
	margin: 0 auto;
    display: block;
    transform: rotate(90deg);
}
.largeVideoCall{
	background-color: brown;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: .2s;
    border-radius: 10px;
}
.smallVideoCall{
	width: 120px;
    height: 140px;
    background: beige;
    transition: .2s;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 10px;
    z-index: 8;
}
.moveVideoCall{
	bottom: 110px;
	transition: .2s;
}
.callHeaderSec,.callAreaSec{
	position: relative;
	z-index: 9;
}
.userGroup{
	display: flex;
	align-items: center;
}
.userGroup img{
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin-right: 4px;
}
.userGroup span{
	font-size: 12px;
    font-weight: 500;
}
.createGroupBtn svg{
	width: 30px;
	height: 30px;
}
.createGroupSideBar{
	position: absolute;
    background-color: #fff;
    width: 100%;
    transition: .3s;
    z-index: 1;
}
.createGroupHead {
	display: flex;
	align-items: center;
}
.createGroupHead h5{
	margin: 0;
	font-size: 18px;
	color: #00473f;
}
.createGroupHead svg{
	fill: #00473f;
}
.createGroupInput input,.createGroupInput input:focus{
	border: 0;
	border-bottom: 1px solid lightgrey;
	font-size: 14px;
	color: #fff;
	border-radius: 0;
	padding: 10px;
	background-color: #00473f;
}
.createGroupInput input::placeholder{
	color: #fff;
	font-size: 14px;
}
.createGroupMultiUser {
	border-bottom: 1px solid #00473f;
	padding: 4px;
}
.createGroupMultiUser > div{
	outline: 0;
    border-color: transparent;
    border: 0;
}
.createGroupBtn{
	text-align: center;
	margin-top: 10px ;
	position: absolute;
    margin: 0 auto;
    left: 40%;
    bottom: 10px;
}
.createGroupBtn .btn{
	background: #00473f;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    padding: 0;
}
.createGroupBtn svg{
	margin-top: 3px;
	fill: #fff;
}
.senderImg{
	margin-right: 10px;
/*    margin-top: -12px;*/
}
.senderImg img{
	width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid lightgrey;
}
.backConversation{
	display: none;
	background: transparent;
    border: 0;
    padding-right: 10px;
    font-size: 20px;
}
.customBlock{
	display: block;
}
.notifyBadge{
	background-color: #00473f;
    color: #fff;
    position: absolute;
    right: 14px;
    top: 6px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 100%;
}
.defaultResults ul{
	padding: 10px 0;
	margin: 0;
	display: flex;
	list-style-type: none;
	border-bottom: 1px solid lightgrey;
	overflow-x: auto;
}
.defaultResults ul li{
	padding: 0 10px;
	cursor: pointer;
	border-right: 1px solid lightgrey;
	width: 48%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.defaultResults ul li:last-child{
	border-right: 0;
}
.defaultResults ul li img{
	width: 36px;
    height: 36px;
    border-radius: 100%;
}
.defaultResults .chatDetail{
	padding: 0;
	margin-right: 0;
}
.userInfoChat span{
	font-size: 16px;
	color: #e0e6ed;
	font-weight: 600;
}
.defaultResults .chatDetail p,.customDropDownButton .dropend .dropdown-toggle::after,.customDropDownButton .dropstart .dropdown-toggle::before{
	display: none;
}
.senderImg small{
	font-size: 12px;
	font-weight: 700;
	text-transform: capitalize;
}
.customDropDownButton button{
	background: transparent;
    border: 0;
    padding: 0;
    line-height: 1;
    transform: rotate(90deg);
    font-size: 20px;
    vertical-align: baseline;
    position: relative;
}
.customDropDownButton .dropend button{
	right: -15px;
}
.customDropDownButton .dropstart button{
	left: 0;
}
.customDropDownButton .show>.btn-primary.dropdown-toggle,.btn-primary:hover{
	background: transparent;
	border: 0;
	border-color: transparent;
}
.customDropDownButton .show>.btn-primary.dropdown-toggle:focus,.btn-primary:active:focus{
	box-shadow: none;
}
.customDropDownButton .dropdown-item{
	padding: 4px 8px;
	font-size: 12px;
}
.customDropDownButton .dropdown-menu{
	min-width: auto;
	padding: 0;
}
.messageBg2 .grpUserName{
	color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    border-bottom: 1px solid #fff;
    margin-bottom: 4px;
    display: block;
    font-size: 12px;
}
.textBox video,.filePreviewPanel video{
	width: 100%;
}
.fileMessageBox button{
	background: transparent;
	border: 0;
}
.loginRoomModal .modal-content{
	padding: 30px;
}
.authForm button,.authForm button:hover,.infoModalBtn{
	background-color: #00473f ;
	color: #fff !important;
}
.passwordField button{
	background-color: transparent;
	color: #212529;
}
.dropdown-item.active, .dropdown-item:active{
	background-color: #e0e6ed;
	color: #000;
}
.chat.scrollBar .customDropDownButton .dropend button{
	color: #000;
	top: -10px;
	right: 0;
	transform: none;
	font-size: 22px;
}
.chat.scrollBar .dropdown-item:focus, .dropdown-item:hover{
	background-color: transparent;
}
.chat.scrollBar .customDropDownButton .dropdown-item{
	padding: 0;
}
.chat.scrollBar .customDropDownButton button{
	vertical-align: super;
}
.chatSearch{
	position: relative;
}
.chatSearch input{
	position: absolute;
    right: 0;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid grey;
    font-size: 14px;
    top: 4px;
    width: 230px;
    outline: 0;
    padding-left: 10px;
    transition: .3s;
}
.chatSearch span{
	position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
}
.react-confirm-alert-body > h1{
	font-size: 14px;
    font-weight: 600;
}
.renameGroup button,.updateGroupModal button{
	font-size: 14px;
    margin-top: 10px;
    margin-left: auto;
    display: block;
    background-color: #00473f;
    border-color: #00473f;
}
.updateGroupModal button.btn-secondary:hover, .renameGroup button.btn-secondary:hover{
	background-color: #00473f;
    border-color: #00473f;
}
.updateGroupModal input{
	font-size: 14px;
}
.updateGroupModal .modal-content{
	padding: 30px;
}
.grpUserList img,.grpSearchUserList img{
	width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: contain;	
    border: 1px solid lightgrey;
    margin-bottom: 6px;
}
.grpUserList button{
	font-size: 10px;
}
.grpUserList p,.grpSearchUserList p{
	margin: 0;
    font-size: 14px;
    text-transform: capitalize;
}
.grpUserList small,.grpSearchUserList small{
	font-size: 12px;
}
.grpUserList{
	border: 1px solid lightgrey;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
}
.grpUserListContent{
	display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.grpSearchUserListSec{
	border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 20px;	
    height: 260px;
    overflow: auto;
}
.grpSearchUserList{
	border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.date-separator{
	background: #e0e6ed;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    display: block;
    width: fit-content;
    padding: 4px 14px;
    margin: 0 auto;
    border-radius: 4px;
}
.disabledInputBar{
	margin: 0;
    position: absolute;
    background: red;
    padding: 13px;
    bottom: 0;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mainContainer .loader{
	margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainContainer .loader span > span{
	background-color: #00473f !important;
}
.queryPcikList select{
	height: 40px;
    border-radius: 6px;
    margin-right: 10px;
    padding: 0 4px;
    outline: 0;
    font-size: 14px;
    color: slategray;
    font-weight: 400;
    width: 200px;
}
.dashboardHeader{
	justify-content: space-between;
}
.dashboardHeader ul,.dashboardHeader{
	padding: 0 10px;
	margin: 0;
	display: flex;
	list-style-type: none;
	align-items: center;
}
.dashboardHeader li a{
	font-size: 16px;
    color: #00473f;
    text-decoration: none;
    margin: 0 16px;
}
.dashboardHeaderLogo img{
	width: 220px;
}
.dashboardHeader{
	width: 100%;
	position: fixed;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  z-index: 9;
}
.dashboardSidebar{
	width: 25%;
	margin-top: 100px;
	border-right: 1px solid lightgrey;
}
.dashboardSidebar ul{
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.dashboardSidebar a{
	padding: 10px 20px;
	font-size: 16px;
	text-decoration: none;
	color: #00473f;
}
.dashboardUserInfo button,.dashboardUserInfo button:hover{
	display: flex;
	align-items: center;
	background-color: #00473f !important;
	padding: 10px 10px;
	border: 0;
	box-shadow: none !important;
    border-radius: 4px;
}
.dashboardUserInfo img{
	width: 30px !important;
    height: 30px;
    border-radius: 100%;
    margin-right: 10px;
}
.dashboardUserInfo .dropdown-item{
	font-size: 14px;
}
.dashboardUserInfo span{
	color: #fff;
	font-size: 14px;
	padding-left: 10px;
}
.dashboardContainer{
	width: 75%;
	margin-top: 100px;
}
.dashboardContent{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 100px);
}
.dashboardContainer h1{
	font-size: 52px;
	color: #00473f;
}
.dashboardContainer span{
	color: #00473f;
	font-size: 24px;
}
.dashboardSidebarUser{
	padding: 0 20px;
}
.selectedUserChat{
	overflow-y: scroll;
}
.selectedUserChat h5{
	font-size: 16px;
    color: #00473f;
}
.selectedUserChat p{
	font-size: 14px;
	color: grey;
}
.selectedUserChat ul{
	padding: 0;
	list-style-type: none;
}
.selectedUserChat li {
	display: flex;
/*	margin: 10px 0;*/
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    cursor: pointer;
}
.selectedUserChat li:last-child{
	border-bottom: 0;
}
.selectedUserChat li .chatImg img{
	width: 30px;
	height: 30px;
	border-radius: 100%;
}
.dashboardChatSearch{
	position: absolute;
	z-index: 9;
	right: 10px;
}
.feedBack ul{
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.feedBack li img{
	width: 38px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
}
.feedBack {
	display: flex;
    align-items: center
}
.feedBack h5{
	margin: 0;
	font-size: 16px;
}
.feedBack li{
	margin: 0 6px;
}
.feedBackEmotions p{
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 6px;
}
.feedBackEmotions ul{
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
    flex-wrap: wrap;
}
.feedBackEmotions li{
	display: flex;
	align-items: center;
	width: 33.333%;
}
.feedBackEmotions label{
	margin-bottom: 0;
}
.feedBackEmotions input{
	margin-right: 4px;
}
.feedBackEmotions{
	margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
    
}
.feedBackEmotionForm .feedBackEmotions:last-child{
	border-bottom: 0;
}
.feedBackEmotionForm textarea{
	height: 100px;
	border-radius: 4px;
	border:1px solid lightgrey;
	width: 100%;
}
.feedbackSec table {
      width: 100%;
      border-collapse: collapse;
    }
    .feedbackSec table, th, td {
      border: 1px solid lightgrey;
    margin: 10px;
    }
    .feedbackSec th, td {
      padding: 8px;
      text-align: left;
    }
.form-select:focus{
	box-shadow: none;
	outline: 0;
	border-color: #00473f;
}
.feedbackSec{
	padding: 30px;
}
.feedbackSec ul{
	border: 0;
}
.css-1atu56z-MuiPaper-root{
	box-shadow: none;
}
.feedbackSec li button{
	border: 1px solid #00473f !important;
    color: #00473f !important;
    background-color: #fff !important;
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    transition: .2s;
}
.feedbackSec li button.active{
	color: #fff !important;
    background-color: #00473f !important;
}
.feedbackSec .MuiPaper-root span,.feedbackSec .MuiPaper-root select,.feedbackSec .MuiPaper-root option{
	font-size: 14px;
}
.feedbackSec .MuiTableCell-body span{
	color: #000 !important;
}
.dateFilter p{
	font-size: 14px;
	font-weight: 500;
	color: #000;
}
.dateFilter input{
	width: 70%;
    padding: 4px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    font-size: 12px;
}
.dateFilter button{
	border: 1px solid #00473f !important;
    color: #fff !important;
    background-color: #00473f !important;
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    transition: .2s;
}
.customSpinner {
	display: flex;
	align-items: center;
	justify-content: center;
}
.voiceRecorder {
	position: absolute;
	bottom: 40px;
}
.voiceRecorder canvas{
	height: 100px;
	width: 300px;
}
.voiceRecorderPlayer{
	width: 300px;
	position: absolute;
    bottom: 40px;
}
.voiceRecorderPlayer audio{
	width: 100% !important;
	height: 40px !important;
}
.imageModal img{
	width: 100%;
	height: 80vh;
}
.zoomBtns{
	position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    background: #fff;
    border-radius: 4px;
}

@media only screen and (max-width: 992px){
	.messageBg2,.messageBg1{
		max-width: none;
	}
	.KbmChatHomeSec{
		width: 60%;
	}
	.sideBarMain{
		width: 40%;
	}
}
@media only screen and (max-width: 776px){
	.KbmChatHomeSec svg{
		width: 200px;
	}
	.notificationSec{
		display: none !important;
	}
}
@media only screen and (max-width: 576px){
	.sideBarMain,.messagePanel{
		width: 100%;
	}
	.KbmChatHomeSec{
		width: 100%;
		display: none;
	}
	.messageScroll{
		padding: 60px 20px 20px;
	}
	.chatHeaderSec{
		position: fixed;
    width: 100%;
    left: 0;
    z-index: 9;
	}
	.messageBg{
		height: 100vh;
	}
	.backConversation{
		display: block;
	}
	.customNone{
		display: none;
	}
}